import { useEffect } from 'react';
import * as Sentry from '@sentry/remix';
import { HydratedRouter } from 'react-router/dom';
import { useLocation, useMatches } from 'react-router';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
  dsn: 'https://55dba657003cf5c80a300083f0fac368@o169956.ingest.us.sentry.io/4508338691375104',
  ignoreErrors: ['Non-Error promise rejection captured'],
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
});

hydrateRoot(document, <HydratedRouter />);
